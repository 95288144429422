import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
const stage = process.env.REACT_APP_STAGE
const SettingModal = ({ show, setShow, updateSettings }) => {
  // const [show, setShow] = useState(false);
  const [values, setValues] = useState({ secret_key: '', public_key: '', name: stage });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUpdate = () => {
    updateSettings(values);
    handleClose()
  }

  const updateValues = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  return (
    <div>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label className="text-left d-block">Secret_Key</Form.Label>
            <Form.Control type="secret_key" placeholder="Enter name"
              name="secret_key"
              onChange={updateValues} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="map-header">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleUpdate}>Update</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SettingModal;
