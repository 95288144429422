import {
  Button,
  Container,
  Dropdown,
  Form,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { userSelector } from "../features/slices/UserSlice";
import logo from "../images/logo.png";
import userImage from "../images/user.png";
import { useSelector, useDispatch } from "react-redux";

const MainHeader = ({ currPage }) => {
  const history = useHistory();
  const { user } = useSelector(userSelector);
  const logOutPress = () => {
    localStorage.removeItem("token");
    history.push("/login");
  };
  const purchasePress = () => {
    history.push('/packages')
  };
  const historyPress = () => {
    history.push('/transactionHistory')
  };
  const homePress = () => {
    history.push('/')
  };
  const dashboardPress = () => {
    history.push('/adminDashboard')
  };
  return (
    <>
      <header className="main-header py-3">
        <div className="container">
          <Navbar expand="lg">
            <Container fluid>
              <Navbar.Brand href="/">
                <img src={logo} alt="logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto my-2 my-lg-0 justify-content-end d-flex w-100"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  {user?.role !== 'ADMIN' &&
                    <li>
                      <strong>Remaining Searches: </strong>{user?.searchCount || '0'}
                    </li>}
                </Nav>
              </Navbar.Collapse>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={userImage} width={25} height={25} alt="user-icon" />
                  <span>{user?.name}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {currPage != 'home' &&
                    <Dropdown.Item onClick={homePress}>Home </Dropdown.Item>}

                  {(user?.role !== 'ADMIN' && currPage != 'purchase') &&
                    <Dropdown.Item onClick={purchasePress}>Purchase </Dropdown.Item>}

                  {(user?.role !== 'ADMIN' && currPage != 'transactionHistory') &&
                    <Dropdown.Item onClick={historyPress}>Transaction History </Dropdown.Item>}

                  {(user?.role === 'ADMIN' && currPage != 'adminDashboard') &&
                    <Dropdown.Item onClick={dashboardPress}>Dashboard</Dropdown.Item>}

                  <Dropdown.Item onClick={logOutPress}> Logout</Dropdown.Item>

                  {/* <Button className="logout-btn">Logout</Button> */}
                </Dropdown.Menu>
              </Dropdown>
            </Container>
          </Navbar>
        </div>
      </header>
    </>
  );
};

export default MainHeader;
