import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

export const MyLoader = ({ show }) => {
  return show ? (
    <div className="loaderContainer">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>

  ) : null;
};
