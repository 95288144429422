import React, { Fragment, useEffect } from "react";
import logo from "../images/logo.png";

const PrivacyPolicy = ({ }) => {

  return (
    <Fragment>
      <div className=" d-flex justify-center login-form">
        <div className="row justify-content-center align-items-center w-100">
          <div className="col-md-8">
            <div className="bg-white py-5 shadow px-5">
              <div className="logo mb-4">
                <img src={logo} alt="logo" />
              </div>

              <h1>
                Privacy Policy
              </h1>

              <p className="privacy-text">
                At All Trucking Data, we are committed to protecting the privacy and security of our users'
                personal information. This Privacy Policy describes how we collect, use, and disclose your
                personal information in connection with our website, alltruckingdata.com, and our services.
                Collection of Personal Information
                <br />
                <br />
                We collect personal information when you visit our website, register for our services, or
                communicate with us. This may include your name, email address, phone number, company
                name, job title, and other information that you voluntarily provide to us.
                <br />
                <br />
                We may also collect certain information automatically when you visit our website, such as your
                IP address, browser type, operating system, and other technical information.
                <br />
                <br />
                <strong>Use of Personal Information</strong>
                <br />
                We use the personal information that we collect for the following purposes:
                <br />
                <ul>
                  <li> To provide our services and communicate with you about your account</li>
                  <li> To improve our website and services</li>
                  <li> To send you marketing communications that we believe may be of interest to you</li>
                  <li> To comply with legal obligations</li>
                </ul>
                <br />
                We may also use your personal information for other purposes that are compatible with the
                above.
                <br />
                <strong>
                  Disclosure of Personal Information</strong>
                <br />
                <br />
                We may disclose your personal information to the following third parses:
                <ul>
                  <li>
                    Service providers that help us provide our services, such as hosting providers, payment
                    processors, and marketing partners
                  </li>
                  <li>Law enforcement agencies or other government entities, as required by law or to
                    protect our rights or the rights of others
                  </li>
                </ul>
                <br />
                We may also disclose your personal information to other third parses with your consent or as
                directed by you.
                <br />
                <br />
                <strong>
                  Security of Personal Information</strong>
                <br />
                We take reasonable measures to protect the security of your personal information, including
                using encryption and other security technologies to protect against unauthorized access,
                disclosure, or use.
                <br />
                <br />
                However, no system can be completely secure, and we cannot guarantee that your personal
                information will be completely secure from unauthorized access, disclosure, or use.
                <br />
                <br />
                <strong>
                  Retention of Personal Information
                </strong>
                <br />
                We retain your personal information for as long as necessary to provide our services and for
                other purposes described in this Privacy Policy, unless a longer retention period is required or
                permitted by law.
                <br />
                <br />
                <strong>
                  Changes to this Privacy Policy
                </strong>
                <br />
                We may update this Privacy Policy from sme to sme by posing a new version on our website.
                We will notify you of any material changes by email or by posting a notice on our website.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default PrivacyPolicy;
