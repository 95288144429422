import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  userSelector,
  getCurrentUser,
  searchResults,
} from "./slices/UserSlice";
import { Link, useHistory } from "react-router-dom";
import { Accordion, Button, Form } from "react-bootstrap";
import search from "../images/search.png";
import markerIcon from "../images/pin-marker.png";
import refresh from "../images/refresh.png";
import zoom from "../images/zoom.png";
import location from "../images/location-pin.png";
// import arrow from "../images/arrow-down.png";
import MainHeader from "../components/MainHeader.js";
import GoogleMapReact from "google-map-react";
// import ModalBox from "../components/ModalBox";
import { HeaderPages, States } from "../utils/Constants";
import ErrorModal from "../components/ErrorModal";
import MyPagination from "../components/Pagination";
import { MyLoader } from "../components/MyLoader";
import OutOfSearchModal from "../components/OutOfSearchModal";
import WarningModal from "../components/WarningModal";
import ZeroResultErrorModal from "../components/ZerorResultErrorModal";
import { reportAProblemAPI, searchResultsAPI } from "../helpers/apiHelper";
import ReportAProblemModal from "../components/ReportAProblemModal";
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

const formDefault = {
  zipCode: "",
  state: "",
  range: "",
  customerType: "",
  noOfVehicles: 0,
  noOfDrivers: 0,
};

const defaultProps = {
  center: {
    lat: 40.607425,
    lng: -111.896941,
  },
  zoom: 11,
};

const Dashboard = () => {
  const history = useHistory();

  const [form, setForm] = useState(formDefault);

  const [loading, setLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [showOutOfCreditError, setShowOutOfCreditError] = useState(false);
  const [showZeroResultError, setShowZeroResultError] = useState(false);
  const [showReportProblem, setShowReportProblem] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [error, setError] = useState("");

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedLocationShow, setSelectedLocationShow] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);

  useEffect(() => {
    const token = localStorage.getItem("token");
    dispatch(getCurrentUser({ token: token }));
  }, [dispatch]);

  const callSearchApi = async (body) => {

    try {
      setLoading(true);
      const sResults = await searchResultsAPI(body);
      setLoading(false);
      if (sResults.status) {
        // console.log('sResults', JSON.stringify(sResults))

        const searchResult = sResults.data
        setLocationList(searchResult?.data);
        setResultCount(searchResult?.count);
        if (searchResult?.noAddressFound) {
          setError("Invalid zip code. No address found against this zip code.");
          setShowError(true);
          return
        }
        if (searchResult?.outOfCredit) {
          setShowOutOfCreditError(true)
          return
        }
        if (!searchResult?.count) {
          setShowZeroResultError(true)
          return
        }
        if (searchResult?.count < 20 && searchResult?.count > 0 && !searchResult.proceed) {
          setShowWarning(true)
        }

      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updateCurrentPage = (page) => {
    setCurrentPage(page);
    searchPress(page, false);
  };
  const onLogOut = () => {
    localStorage.removeItem("token");
    history.push("/login");
  };

  const searchButtonPress = (e) => {
    e?.preventDefault();
    searchPress(1, false);
    setCurrentPage(1);
  };
  const proceedPress = () => {
    searchPress(1, true);
    setShowWarning(false)
  }
  const refreshButtonPress = (e) => {
    e?.preventDefault();
    setForm(formDefault);
  };
  const searchPress = (page, proceed) => {
    console.log("form", form);
    if (form.zipCode === "") {
      setError("Please enter zip code ");
      setShowError(true);
      return;
    }
    if (form.range === "") {
      setError("Please select range");
      setShowError(true);
      return;
    }
    if (form.noOfVehicles === 0 || form.noOfVehicles === "") {
      setError("Please select trucks owned");
      setShowError(true);
      return;
    }
    if (form.customerType === "") {
      setError("Please select customer type");
      setShowError(true);
      return;
    }
    form.page = page;
    form.proceed = proceed
    const token = localStorage.getItem("token");
    callSearchApi(form)
    // dispatch(searchResults({ token: token, body: form }));
  };



  function handleChange(e) {
    let value = e.target.value;
    if (
      e.target.value === "State" ||
      e.target.value === "Number of Drivers" ||
      e.target.value === "Truck Owned" ||
      e.target.value === "Type of Customers" ||
      e.target.value === "Range"
    ) {
      value = "";
    }
    let updateField = {};
    if (e.target.name === "zipCode") {
      updateField["state"] = "";
    }
    if (e.target.name === "state") {
      updateField["zipCode"] = "";
    }
    if (e.target.name === "customerType") {
      updateField["customerType"] = "";
    }
    setForm({ ...form, ...updateField, [e.target.name]: value });
  }

  const cardPress = (locationObj) => {
    setSelectedLocation(locationObj)
  };

  const upgradePress = () => {
    setShowOutOfCreditError(false)
    setTimeout(() => {
      history.push("/packages");
    }, 1000);
  }

  const reportProblemShowBox = async (marker) => {
    setShowReportProblem(true)
  }
  const reportProblem = async (text) => {
    try {
      setLoading(true);
      const response = await reportAProblemAPI({ problem: text });
      setShowReportProblem(false)
      setLoading(false);
      if (response.status) {
        // console.log('sResults', JSON.stringify(response))
      }
    } catch (error) {
      setLoading(false);
    }
  }


  const AnyReactComponent = ({ text, marker, sShow, setSShow, reportProblem }) => {
    const [show, setShow] = useState(false);
    return (
      <div key={marker._id}>
        <div className="pin-map-box">
          <img
            src={markerIcon}
            alt="icon"
            width={40}
            height={40}
            onClick={() => setSShow(marker)}
          />

          {(sShow && marker._id === sShow?._id) && (
            <div className="marker-text" >
              <div>
                <div className="title-container">
                  <h6>{marker?.DBA_NAME || marker.LEGAL_NAME}</h6>
                  <h5 onClick={() => { setSShow(null) }}>X</h5>
                </div>
                <p>{marker?.PHY_STREET +
                  " " +
                  marker?.PHY_STATE +
                  ", " +
                  marker?.PHY_CITY +
                  " " +
                  marker?.PHY_COUNTRY}{" "}</p>
                <Link to="/">{marker?.EMAIL_ADDRESS}</Link>
                <p>
                  <strong>Phone:</strong>{marker?.TELEPHONE}
                </p>
                <p>
                  <strong>Trucks Owned:</strong>{marker?.NBR_POWER_UNIT}
                </p>
                <p>
                  <strong>Drivers:</strong>{marker?.DRIVER_TOTAL}
                </p>
                <p>{marker.LEGAL_NAME}</p>
                <Button onClick={() => reportProblem(marker)}>Report a Problem</Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const exportPress = (e) => {
    e.preventDefault()




    const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const js = locationList.map(item => {
      const newObj = { ...item, coordinates: item.loc?.coordinates[0] + "," + item.loc?.coordinates[1] }
      delete newObj.loc
      return newObj
    })
    const ws = XLSX.utils.json_to_sheet(js)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: type })
    FileSaver.saveAs(data, 'TruckingData.xlsx')

  }


  return (
    <div>
      <MainHeader currPage={"home"} />
      <div className="container">
        <div className="map-header mt-3">
          <Form>
            <div className="row">
              <div className="col-md-2">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Zip Code"
                    name="zipCode"
                    value={form.zipCode}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              {/* <div className="col-md-2">
                <Form.Select
                  aria-label="Default select example"
                  name="state"
                  value={form.state}
                  onChange={handleChange}
                >
                  <option>State</option>
                  {States.map((state, index) => (
                    <option key={index} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </Form.Select>
              </div> */}
              <div className="col-md-2">
                <Form.Select
                  aria-label="Default select example"
                  name="range"
                  value={form.range}
                  onChange={handleChange}
                >
                  <option>Range</option>
                  <option value={10}>10 Miles</option>
                  <option value={25}>25 Miles</option>
                  <option value={50}>50 Miles</option>
                  <option value={100}>100 Miles</option>
                  <option value={200}>200 Miles</option>
                </Form.Select>
              </div>
              <div className="col-md-2">
                <Form.Select
                  aria-label="Default select example"
                  name="customerType"
                  onChange={handleChange}
                >
                  <option>Type of Customers</option>
                  {/* <option value="DealerShip">DealerShip</option> */}
                  <option value="Fleet">Fleet</option>
                  {/* <option value="Independent Garage">Independent Garage</option>
                  <option value="Towing">Towing</option>
                  <option value="Truck Repair">Truck Repair</option> */}
                </Form.Select>
              </div>
              {/* <div className="col-md-2">
                <Form.Select
                  aria-label="Default select example"
                  name="noOfDrivers"
                  value={form.noOfDrivers}
                  onChange={handleChange}
                >
                  <option>Number of Drivers</option>
                  <option value="0-9">0-9</option>
                  <option value="10-25">10-25</option>
                  <option value="26-50">26-50</option>
                  <option value="51-100">51-100</option>
                  <option value="101-500">101-500</option>
                  <option value="500+">500+</option>
                </Form.Select>
              </div> */}
              <div className="col-md-2">
                <Form.Select
                  aria-label="Default select example"
                  name="noOfVehicles"
                  value={form.noOfVehicles}
                  onChange={handleChange}
                >
                  <option>Truck Owned</option>
                  <option value="0-9">0-9</option>
                  <option value="10-25">10-25</option>
                  <option value="26-50">26-50</option>
                  <option value="51-100">51-100</option>
                  <option value="101-500">101-500</option>
                  <option value="500+">500+</option>
                </Form.Select>
              </div>
              <div className="col-md-4">
                <button onClick={searchButtonPress}>
                  {/* {isFetching ? <MyLoader show={isFetching}/> :
                    <img src={search} alt="icon" />
                  } */}
                  <img src={search} alt="icon" />
                </button>

                <button onClick={refreshButtonPress}>
                  <img src={refresh} alt="icon" />
                  {/* {isFetching ? <MyLoader show={isFetching}/> :
                    <img src={refresh} alt="icon" />
                  } */}
                </button>
                {user?.role === 'ADMIN' && <button onClick={exportPress}
                disabled={!locationList.length}
                >
                  {/* <img src={refresh} alt="icon" /> */}
                  Export
                </button>}
              </div>
            </div>
          </Form>
        </div>
        <div className="row cl">
          <div className="col-md-9">
            <div style={{ height: "70vh", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  // key: "AIzaSyCQnrFtSSdCvG7PFfGvotppt7Cxbd1RnuM",
                  key: "AIzaSyCaIorrdyxQ7aX9HFJdrD1k3aHWLJgldoc",
                }}
                center={
                  selectedLocation ? {
                    lat: selectedLocation.loc.coordinates[1],
                    lng: selectedLocation.loc.coordinates[0],
                  } :
                    (locationList.length
                      ? {
                        lat: locationList[0].loc.coordinates[1],
                        lng: locationList[0].loc.coordinates[0],
                      }
                      : defaultProps.center)
                }
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                options={(maps) => ({
                  mapTypeControl: true,
                  mapTypeId: maps.MapTypeId.ROADMAP,
                  mapTypeControlOptions: {
                    style: maps.MapTypeControlStyle.DEFAULT,
                    position: maps.ControlPosition.LEFT_TOP,
                    mapTypeIds: [
                      maps.MapTypeId.ROADMAP,
                      maps.MapTypeId.SATELLITE,
                      maps.MapTypeId.HYBRID
                    ]
                  },
                })}
              >
                {locationList.map((marker, index) => {
                  return (
                    <AnyReactComponent
                      lat={marker.loc.coordinates[1]}
                      lng={marker.loc.coordinates[0]}
                      text={marker.LEGAL_NAME}
                      marker={marker}
                      key={index}
                      sShow={selectedLocationShow}
                      setSShow={setSelectedLocationShow}
                      reportProblem={reportProblemShowBox}
                    />
                  );
                })}
              </GoogleMapReact>
            </div>
          </div>

          <div className="col-md-3 map-right-box">
            {resultCount > 0 && <span className="bg-white p-2 w-100 d-block mt-3">
              Record Found:{resultCount}
            </span>}
            {resultCount === 0 && <span className="bg-white p-2 w-100 d-block mt-3">
              Results
            </span>}
            <div className="main-card-box">
              {locationList.map((locationResult, index) => {
                return <LocationResultCard key={index} data={locationResult} onPress={cardPress} />;
              })}
            </div>
            <MyPagination
              length={resultCount}
              pageSize={20}
              currentPage={currentPage}
              setCurrentPage={updateCurrentPage}
            />
            {resultCount > 20 && <p className="paginationNote">You will be charged 1 search credit by loading the next page</p>}

          </div>
        </div>
      </div>
      <ErrorModal error={error} show={showError} setShow={setShowError} />
      <OutOfSearchModal show={showOutOfCreditError} setShow={setShowOutOfCreditError} upgradePress={upgradePress} />
      <WarningModal show={showWarning} setShow={setShowWarning} proceedPress={proceedPress} />
      <ZeroResultErrorModal show={showZeroResultError} setShow={setShowZeroResultError} />
      <ReportAProblemModal show={showReportProblem} setShow={setShowReportProblem} reportPress={reportProblem} />
      <MyLoader show={loading} />
    </div>
  );
};

export default Dashboard;

const LocationResultCard = ({ data, index, onPress }) => {
  return (
    <div key={index} className="card" onClick={() => onPress(data)}>
      <div className="d-flex justify-content-between">
        <h5>{data?.DBA_NAME || data?.LEGAL_NAME}</h5>
        <div>
          <img src={location} width={20} height={20} alt="icon" />
        </div>
      </div>

      <p>
        {data?.PHY_STREET +
          " " +
          data?.PHY_STATE +
          ", " +
          data?.PHY_CITY +
          " " +
          data?.PHY_COUNTRY}{" "}
      </p>
      <p>{"Telephone:  " + data?.TELEPHONE} </p>
      {/* <p>{"Power Units:  " + data?.NBR_POWER_UNIT} </p> */}
      <div className="detail-card ">
        {/* <div className="d-flex align-items-center">
          <img src={zoom} width={20} height={20} alt="icon" />
          <button>Read More</button>
        </div> */}
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <img
                className="me-4"
                src={zoom}
                width={20}
                height={20}
                alt="icon"
              />
              Read More
            </Accordion.Header>
            <Accordion.Body>
              <Link to="/">{data?.EMAIL_ADDRESS}</Link>
              <p>
                <strong>Truck Owned: </strong>
                {data?.NBR_POWER_UNIT}
              </p>
              <p>
                <strong>Driver: </strong>
                {data?.DRIVER_TOTAL}
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* <div>
          <img src={arrow} width={15} height={15} alt="icon" />
        </div> */}
      </div>
    </div>
  );
};
