import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from "./features/Login";
import Signup from "./features/Signup";
import Dashboard from "./features/Dashboard";
import { PrivateRoute } from "./helpers/PrivateRoute";
import Packages from "./features/Packages";
import AdminDashboard from "./features/AdminDashboard";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import TransactionHistory from "./features/TransactionHistory";
import Welcome from "./features/Welcome";
import ResetPassword from "./features/ResetPassword";
import PrivacyPolicy from "./features/PrivacyPolicy";


// const STRIPE_PUBLISHABLE_KEY = 'pk_test_51JuEYtF7LGMAu4g1F39oNIYpbr593E0Hj7XIvTpJnb4B8oTP0p7YS3cEERupXrXZRMBzp4xVzgAGO2YYX3SexF1O00UYDTVi7c'
// const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function App() {
  return (
    <div className="App">
      {/* <Elements stripe={stripePromise}> */}
        <Router>
          <Switch>
            <Route exact component={Login} path="/login" />
            <Route exact component={ResetPassword} path="/reset-password" />
            <Route exact component={Signup} path="/signup" />
            <Route exact component={PrivacyPolicy} path="/privacy-policy" />
            <PrivateRoute exact component={Dashboard} path="/" />
            <PrivateRoute exact component={AdminDashboard} path="/adminDashboard" />
            <PrivateRoute exact component={Packages} path="/packages" />
            <PrivateRoute exact component={TransactionHistory} path="/transactionHistory" />
            <Route exact component={Welcome} path="/confirm/:confirmationCode" />
          </Switch>
        </Router>
      {/* </Elements> */}
    </div>
  );
}

export default App;
