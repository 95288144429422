import React from "react";
import { useState } from "react";
import refresh from "../images/refresh.png";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

const WarningModal = ({ error, show, setShow, proceedPress }) => {
  // const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="d-inline-flex">
      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="logo logo-2 mb-4">
            <img src={logo} alt="logo" />
          </div>
          <strong>
            There are less than 20 results for your search criteria, by proceeding you will be charged for a full search credit, regardless of the number of results displayed.
          </strong>

          <p className="pb-2 pt-3">
            *It is recommended to open your search criteria*
          </p>
          <button onClick={proceedPress} className="w-75 m-auto d-block justify-center py-2 px-4 mt-4 mb-4 border upgrade-btn">
            Proceed
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WarningModal;
