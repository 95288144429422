import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import logo from "../images/logo.png";
import { loginUser, userSelector, clearState } from "./slices/UserSlice";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { MdLock } from "react-icons/md";
import ReportAProblemModal from "../components/ReportAProblemModal";
import { reportAProblemAPI, techSupportAPI } from "../helpers/apiHelper";
import TechSupportModal from "../components/TechSupportModal";

const Login = ({ }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showReportProblem, setShowReportProblem] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(userSelector);
  const onSubmit = (data) => {
    dispatch(loginUser(data));
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      history.push("/");
    }
  }, [isError, isSuccess]);

  const reportProblemShowBox = async () => {
    setShowReportProblem(true)
  }
  const reportProblem = async (obj) => {
    try {
      setLoading(true);
      const response = await techSupportAPI({ problem: obj.text, name: obj.name, email: obj.email });
      setShowReportProblem(false)
      setLoading(false);
      if (response.status) {
        toast.success('Message received, we will get back to you soon.')
        // console.log('sResults', JSON.stringify(response))
      }
    } catch (error) {
      setLoading(false);
    }
  }


  return (
    <Fragment>
      <div className=" d-flex justify-center align-items-center login-form">
        <div className="row justify-content-center align-items-center w-100">
          <div className="col-md-5">
            <div className="bg-white py-5 shadow px-5">
              <div className="logo mb-4">
                <img src={logo} alt="logo" />
              </div>
              <form
                className="space-y-6"
                onSubmit={handleSubmit(onSubmit)}
                method="POST"
              >
                <div>
                  <div className="mt-1">
                    <div className="position-relative">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Email Address"
                        ref={register({
                          pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
                        })}
                        required
                        className="appearance-none block w-100 border"
                      />
                      <div className="icon-box">
                        <MdEmail fill="#6e6e6e" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="position-relative">
                    <input
                      id="password"
                      placeholder="Password"
                      name="password"
                      type="password"
                      ref={register({ required: true })}
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-100 border"
                    />
                    <div className="icon-box">
                      <MdLock fill="#6e6e6e" />
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-100 d-block justify-center py-2 px-4 mt-4 mb-4 border "
                  >
                    {isFetching ? (
                      <svg
                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : null}
                    Login{" "}
                  </button>
                </div>
              </form>
              <div class="mt-6">
                <div class="relative signup-btn-box">
                  <div class="relative flex justify-center text-sm">
                    <span class="px-2 bg-white text-gray-500">
                      Don't have an account? <Link to="signup"> Signup</Link>
                    </span>
                  </div>
                </div>
                <div className="login-bottom">
                  <ul className="d-flex">
                    <li>
                      <Link to="/reset-password">Reset Password</Link>
                    </li>
                    <li>
                      <Link onClick={reportProblemShowBox}>Tech Support</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TechSupportModal message={'Tech Support'} show={showReportProblem} setShow={setShowReportProblem} reportPress={reportProblem} />
    </Fragment>
  );
};

export default Login;
