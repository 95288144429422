import React, { useState, useEffect } from "react";
import { useDispatch, } from "react-redux";
import { getCurrentUser } from "./slices/UserSlice";
import MainHeader from "../components/MainHeader";
import { Form } from "react-bootstrap";
import { sendPurchaseRequestApi } from "../helpers/apiHelper";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { MyLoader } from "../components/MyLoader";
import { Link, useHistory } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "rgb(240, 57, 122)",
      color: "rgb(240, 57, 122)",
      fontSize: "16px",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF"
      }
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};

function CardSection() {
  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
}


const Packages = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfSearches, setNumberOfSearches] = useState(0);
  const [pricePerCredit, setPricePerCredit] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("token");
    dispatch(getCurrentUser({ token: token }));
  }, []);

  const sendPurchaseRequest = async (token) => {

    try {
      setIsLoading(true);
      const purchase = await sendPurchaseRequestApi({
        body: {
          stripe_token: token,
          amount: numberOfSearches * pricePerCredit,
          no_of_searches: numberOfSearches,
        }
      });
      setIsLoading(false);
      if (purchase.status) {
        // console.log('response', JSON.stringify(purchase))
        // alert(purchase.message)
        history.push("/");

      }
    } catch (error) {
      setIsLoading(false);
    }
  };


  const handleSubmit = async event => {
    event.preventDefault();

    const { stripe, elements } = props;
    console.log('stripe', stripe, elements)
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      console.log(result.error.message);
    } else {
      console.log(result.token);
      await sendPurchaseRequest(result.token.id);
    }
  };

  const handleChange = (e) => {
    setNumberOfSearches(e.target.value);
  };

  return (
    <div>
      {/* <ErrorModal error={error} show={showError} setShow={setShowError} /> */}
      <MainHeader currPage={"purchase"} />
      <div className="container">
        <div className="map-header mt-3">
          <Form>
            <div className="row justify-content-center">
              <div className="col-md-6 purchase-detail-box">
                <div className="purchase-heading">
                  <h1>Purchase Detail</h1>
                  <p>
                    <strong>Purchase per Search: </strong>{pricePerCredit}$
                  </p>
                </div>
                <Form.Label>Enter Number of Searches to Purchase</Form.Label>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="number"
                    placeholder="Type required searches"
                    name="numberOfSearches"
                    value={numberOfSearches}
                    onChange={handleChange}
                  />
                </Form.Group>
                <div className="purchase-heading">
                  <p>
                    <strong>Search Credits: </strong>{numberOfSearches}
                  </p>
                </div>
                <div className="purchase-heading">
                  <p>
                    <strong>Price Per Credit: </strong>{pricePerCredit}$
                  </p>
                </div>
                <div className="purchase-heading">
                  <p>
                    <strong>Total Price: </strong>{numberOfSearches * pricePerCredit}$
                  </p>
                </div>
                <h2>Enter Payment Details</h2>
                <CardSection />
                <button onClick={handleSubmit}>
                  {isLoading ? <MyLoader show={isLoading} /> : 'Purchase Now'}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

// export default Packages;


export default function InjectedCheckoutForm() {
  const STRIPE_PUBLISHABLE_KEY = 'pk_test_51JuEYtF7LGMAu4g1F39oNIYpbr593E0Hj7XIvTpJnb4B8oTP0p7YS3cEERupXrXZRMBzp4xVzgAGO2YYX3SexF1O00UYDTVi7c'
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Packages stripe={stripe} elements={elements} />
        )}
      </ElementsConsumer>
    </Elements>
  );
}

