import React from "react";
import { useState } from "react";
import refresh from "../images/refresh.png";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

const OutOfSearchModal = ({ error, show, setShow, upgradePress }) => {
  // const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const upgradeButton = () => {
    handleClose(false)
    upgradePress();
  }
  return (
    <div className="d-inline-flex">
      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="logo logo-2 mb-4">
            <img src={logo} alt="logo" />
          </div>
          <p>
            Sorry, friend, but you’re all out of search credits. To proceed with your search, please purchase additional search credits.
          </p>

          {/* <p className="pb-2 pt-3">
            Unlock additional searches by upgrading to our{" "}
            <strong>Standard</strong> or <strong>Professional</strong> plan.
          </p> */}
          <button onClick={() => upgradeButton()} className="w-75 m-auto d-block justify-center py-2 px-4 mt-4 mb-4 border upgrade-btn">
            Buy Credits Now
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OutOfSearchModal;
