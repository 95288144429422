import React from "react";
import { useState } from "react";
import refresh from "../images/refresh.png";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

const ReportAProblemModal = ({ message, show, setShow, reportPress }) => {
  const [text, setText] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const reporProblem = () => {
    // const text = 'this is text'
    if (text == '') return
    reportPress(text)
  }
  return (
    <div className="d-inline-flex">
      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{message ? message : 'Report A Problem'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p className="pb-2 pt-3">
            Tell us your problem
          </p>

          <textarea className="textareaReport" id="w3review" name="w3review" rows="4" cols="60"
            onChange={e => setText(e.target.value)}
            placeholder="Write here...">

          </textarea>

          {/* <p className="pb-2 pt-3">
            *It is recommended to open your search criteria*
          </p> */}
          <button onClick={reporProblem} className="w-75 m-auto d-block justify-center py-2 px-4 mt-4 mb-4 border upgrade-btn">
            Proceed
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ReportAProblemModal;
