import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import logo from "../images/logo.png";

import { useSelector, useDispatch } from "react-redux";
import { signupUser, userSelector, clearState } from "./slices/UserSlice";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { MdEmail, MdLock } from "react-icons/md";

const Signup = () => {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm();
  const history = useHistory();

  const successMessage =
    "Account was registered successfully! Please check your email";

  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(userSelector);
  const onSubmit = (data) => {
    dispatch(signupUser(data));
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success(successMessage);
      dispatch(clearState());
      history.push("/login");
    }

    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [isSuccess, isError]);

  return (
    <Fragment>
      <div className="d-flex justify-center align-items-center login-form">
        <div className="row justify-content-center align-items-center w-100 hv-100">
          <div className="col-md-5">
            <div className="bg-white py-5 shadow px-5">
              <div className="logo mb-4">
                <img src={logo} alt="logo" />
              </div>
              <form
                className="space-y-6"
                onSubmit={handleSubmit(onSubmit)}
                method="POST"
              >
                <div>
                  <div className="mt-1">
                    {/* <div className="position-relative"> */}
                    <input
                      placeholder="Name"
                      id="name"
                      name="name"
                      type="text"
                      ref={register({ required: true })}
                      autocomplete="name"
                      required
                      className="appearance-none block w-100 border"
                    />
                    {/* <div className="icon-box">
                      < fill="#6e6e6e" />
                    </div>
                  </div> */}
                  </div>
                </div>
                <div>
                  <div className="mt-1">
                    <div className="position-relative">
                      <input
                        placeholder="Email"
                        id="email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        required
                        ref={register({
                          pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
                        })}
                        className="appearance-none block w-100 border"
                      />
                      <div className="icon-box">
                        <MdEmail fill="#6e6e6e" />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="mt-1">
                    <div className="position-relative">
                      <input
                        placeholder="Password"
                        id="password"
                        name="password"
                        type="password"
                        ref={register({ required: true })}
                        autocomplete="current-password"
                        required
                        className="appearance-none block w-100 border"
                      />
                      <div className="icon-box">
                        <MdLock fill="#6e6e6e" />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-100 d-block justify-center py-2 px-4 mt-4 mb-4 border"
                  >
                    {isFetching ? (
                      <Fragment>
                        <svg
                          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </Fragment>
                    ) : (
                      <div> Sign up</div>
                    )}
                  </button>
                </div>
              </form>
              <div class="mt-6">
                <div class="relative signup-btn-box">
                  <div class="relative flex justify-center text-sm">
                    <span class="px-2 bg-white text-gray-500">
                      Have an account? <Link to="login"> Login</Link>
                    </span>
                  </div>
                </div>
                <div className="login-bottom">
                  <ul className="d-flex">
                    <li>
                      <Link to="/reset-password">Reset Password</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Signup;
