import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import logo from "../images/logo.png";
import { loginUser, userSelector, clearState } from "./slices/UserSlice";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { MdLock } from "react-icons/md";
import { resetPasswordAPI, resetPasswordUpdateAPI } from "../helpers/apiHelper";

const ResetPassword = ({ }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [otpSent, setOtpSent] = useState(false)
  const [otp, setOtp] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { register, errors, handleSubmit } = useForm();

  const onSubmit = (data) => {
    setEmail(data.email)
    callResetPasswordUser(data)
  };
  const onSubmit2 = (e) => {
    e.preventDefault()
    
    callResetPasswordUpdate({email: email, otp: otp, password:password, confirmPassword:confirmPassword})
  };

  const callResetPasswordUser = async (user) => {
    try {
      setLoading(true);
      const res = await resetPasswordAPI({ body: user });
      setLoading(false);
      if (res.status) {
        setOtpSent(true)
        toast.success("Please check your email to reset password.");
        // alert("Please check your email to reset password.");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const callResetPasswordUpdate = async (data) => {
    try {
      setLoading(true);
      const res = await resetPasswordUpdateAPI({ body: data });
      setLoading(false);
      if (res.status) {
        setOtpSent(true)
        toast.success('Password reset successfully. Please login.')
        // alert("Please check your email to reset password.");
        history.push('/login')
      }
    } catch (error) {
      setLoading(false);
    }
  };


  return (
    <div>
      {!otpSent && <Fragment>
        <div className=" d-flex justify-center align-items-center login-form">
          <div className="row justify-content-center align-items-center w-100">
            <div className="col-md-5">
              <div className="bg-white py-5 shadow px-5">
                <div className="logo mb-4">
                  <img src={logo} alt="logo" />
                </div>
                <form
                  className="space-y-6"
                  onSubmit={handleSubmit(onSubmit)}
                  method="POST"
                >
                  <div>
                    <div className="mt-1">
                      <div className="position-relative">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          placeholder="Email Address"
                          ref={register({
                            pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
                          })}
                          required
                          className="appearance-none block w-100 border"
                        />
                        <div className="icon-box">
                          <MdEmail fill="#6e6e6e" />
                        </div>
                      </div>
                    </div>
                  </div>


                  <div>
                    <button
                      type="submit"
                      className="w-100 d-block justify-center py-2 px-4 mt-4 mb-4 border "
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
                <div className="mt-6">
                  <div className="relative signup-btn-box">
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-white text-gray-500">
                        <Link to="login">Login</Link>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>}

      {otpSent && <Fragment>
        <div className=" d-flex justify-center align-items-center login-form">
          <div className="row justify-content-center align-items-center w-100">
            <div className="col-md-5">
              <div className="bg-white py-5 shadow px-5">
                <div className="logo mb-4">
                  <img src={logo} alt="logo" />
                </div>
               
                  <div>
                    <div className="mt-1">
                      <div className="position-relative">
                        <input
                          id="otp"
                          name="otp"
                          type="text"
                          placeholder="OTP"
                          value={otp}
                          onChange={text=>setOtp(text.target.value)}
                          ref={register({
                            pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
                          })}
                          required
                          className="appearance-none block w-100 border"
                        />
                        {/* <div className="icon-box">
                          <MdEmail fill="#6e6e6e" />
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-1">
                      <div className="position-relative">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={text=>setPassword(text.target.value)}
                          required
                          className="appearance-none block w-100 border"
                        />
                        {/* <div className="icon-box">
                          <MdEmail fill="#6e6e6e" />
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-1">
                      <div className="position-relative">
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={text=>setConfirmPassword(text.target.value)}
                          required
                          className="appearance-none block w-100 border"
                        />
                        {/* <div className="icon-box">
                          <MdEmail fill="#6e6e6e" />
                        </div> */}
                      </div>
                    </div>
                  </div>


                  <div>
                    <button
                    onClick={onSubmit2}
                      type="submit"
                      className="w-100 d-block justify-center py-2 px-4 mt-4 mb-4 border "
                    >
                      Reset Password
                    </button>
                  </div>

                <div className="mt-6">
                  <div className="relative signup-btn-box">
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-white text-gray-500">
                        <Link to="login">Login</Link>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>}
    </div>
  );
};

export default ResetPassword;
