import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "./slices/UserSlice";
import MainHeader from "../components/MainHeader";
import { Button, Form } from "react-bootstrap";
import {
  fetchAllUsersAPI,
  fetchSearchHistoryAPI,
  sendPurchaseRequestApi,
  updateSettingsApi,
  updateUserAPI,
} from "../helpers/apiHelper";
import EditModal from "../components/EditModal";
import { MyLoader } from "../components/MyLoader";
import moment from "moment";
import SettingModal from "../components/SettingModal";
import MyPagination from "../components/Pagination";
import toast from "react-hot-toast";


const AdminDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSettings, setShowSetting] = useState(false);
  const [settingLoading, setSettingLoading] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [selected, setSelected] = useState("users");


  const settingsPress = async () => {
    setShowSetting(true)
  }

  const updateSettings = async (values) => {
    try {
      setSettingLoading(true);
      const res = await updateSettingsApi({ body: values });
      setSettingLoading(false);
      if (res.status) {
        toast.success('Update Successful');
      }
    } catch (error) {
      setSettingLoading(false);
    }
  };

  return (
    <div>
      {/* <ErrorModal error={error} show={showError} setShow={setShowError} /> */}
      <MainHeader currPage={"adminDashboard"} />
      <div className="">
        <div className="map-header mt-3">
          <div className="row justify-content-center">
            <div className="col-md-2 side-bar">
              <button onClick={() => setSelected('users')}>Users</button>
              <button onClick={() => setSelected('searchHistory')}>Search history</button>
              <button onClick={settingsPress}>Settings</button>
            </div>


            {selected === "users" &&
              <UsersList />
            }
            {selected === "searchHistory" &&
              <SearchHistoryList />
            }
          </div>
        </div>
      </div>
      <SettingModal show={showSettings} setShow={setShowSetting} updateSettings={updateSettings} />
      <MyLoader loading={isLoading} />
    </div>
  );
};

export default AdminDashboard;



const UsersList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    try {
      setIsLoading(true);
      const users = await fetchAllUsersAPI();
      setIsLoading(false);
      if (users.status) {

        const uList = (users.list || []).map((item, index) => ({ ...item, id: index + 1 }))

        setAllUsers(uList);
        setFilteredUsers(uList);
        updateCurrentPage(1, uList)
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const callUpdateUser = async (user) => {
    try {
      setIsLoading(true);
      const res = await updateUserAPI({ body: user });
      setIsLoading(false);
      if (res.status) {
        fetchAllUsers();
        toast.success('Update Successful');
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteUser = async (userId, status) => {
    const user = { id: userId, deleteStatus: status }
    try {
      setIsLoading(true);
      const res = await updateUserAPI({ body: user });
      setIsLoading(false);
      if (res.status) {
        fetchAllUsers();
        toast.success('Update Successful');
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const searchHandle = (e) => {
    const value = e.target.value.toLowerCase();
    const fUsers = allUsers.filter(
      (item) =>
        item.email.toLowerCase().includes(value) ||
        item.name.toLowerCase().includes(value) ||
        item._id.toLowerCase().includes(value)
    );
    setFilteredUsers(fUsers);
    updateCurrentPage(1, fUsers)
  };

  const updateUser = (user, updatedValue) => {
    callUpdateUser({ ...updatedValue, id: user._id });
  };


  const updateCurrentPage = (page, filteredUsers) => {
    setCurrentPage(page);
    const pageSize = 20
    setPageData(filteredUsers.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize))
  };

  return (
    <div className="col-md-9 table-box">
      <div className="row justify-content-between m-4 ">
        <div className="col-md-4">Users </div>
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              onChange={searchHandle}
            />
          </Form>
        </div>
      </div>
      <MyLoader show={isLoading} />

      <table>
        <tbody>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Search Count</th>
            <th>Status</th>
            <th>Delete Status</th>
            <th>action</th>
          </tr>
          {pageData.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item?.id}</td>
                <td>{item?.name}</td>
                <td>{item?.email}</td>
                <td>{item?.searchCount}</td>
                <td>{item?.status}</td>
                <td>{item?.deleteStatus ? 'Blocked' : 'Active'}</td>
                <td>
                  <div className="buttonsC">
                    <EditModal
                      user={item}
                      updateUser={updateUser}
                      deleteUser={deleteUser}
                    />
                    {/* <Button className="edit-btn" variant="primary" onClick={()=>deleteUser(item._id)}>
                        Delete
                      </Button> */}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>

        <MyPagination
          length={filteredUsers.length}
          pageSize={20}
          currentPage={currentPage}
          setCurrentPage={(page) => updateCurrentPage(page, filteredUsers)}
        />
      </table>

    </div>
  )
}

const SearchHistoryList = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchSearchHistory()
  }, [])

  const fetchSearchHistory = async () => {
    try {

      setIsLoading(true);
      const sHistory = await fetchSearchHistoryAPI();
      setIsLoading(false);
      if (sHistory.status) {
        const sh = (sHistory.list || []).map((item, index) => ({ ...item, id: index + 1 }))
        setSearchHistory(sh);
        updateCurrentPage(1, sh)
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateCurrentPage = (page, list) => {
    setCurrentPage(page);
    const pageSize = 20
    setPageData(list.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize))
  };

  return (
    (
      <div className="col-md-9 table-box">
        <div className="row justify-content-between m-4 ">
          <div className="col-md-4">Search History</div>
          <div className="col-md-4"></div>
        </div>
        <MyLoader show={isLoading} />
        {!isLoading && (
          <table>
            <tbody>
              <tr>
                <th>#</th>
                <th>User Id</th>
                <th>Zip Code</th>
                <th>State</th>
                <th>Range </th>
                <th>Customer Type</th>
                <th>No Of Vehicles</th>
                <th>Date</th>
              </tr>
              {pageData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.id}</td>
                    <td>{item?.userId}</td>
                    <td>{item?.zipCode || "-"}</td>
                    <td>{item?.state || "-"}</td>
                    <td>{item?.range || "-"}</td>
                    <td>{item?.customerType || "-"}</td>
                    <td>{item?.noOfVehicles || "-"}</td>
                    <td>{moment(item?.createdAt).format("LLLL")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <MyPagination
          length={searchHistory.length}
          pageSize={20}
          currentPage={currentPage}
          setCurrentPage={(page) => updateCurrentPage(page, searchHistory)}
        />
      </div>
    )
  )
}