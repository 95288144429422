import React from "react";
import { useState } from "react";
import refresh from "../images/refresh.png";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const ErrorModal = ({error, show, setShow}) => {
  // const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="d-inline-flex">
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title >{'Error'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
      </Modal>
    </div>
  );
};

export default ErrorModal;
