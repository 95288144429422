
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { MyLoader } from "../components/MyLoader";
import { verifyUserEmail } from "../helpers/apiHelper";

const Welcome = (props) => {

  const [isLoading, setIsLoading] = useState('wait')

  useEffect(async () => {
    if (props?.match?.path === "/confirm/:confirmationCode") {

      const res = await verifyEmail(props?.match?.params?.confirmationCode)
      if (res.status) {
        setIsLoading('done')
      }
      else {
        setIsLoading('fail')
      }
    }

  }, [])

  const verifyEmail = async (code) => {
    return await verifyUserEmail(code);
  }
  // if (props?.match?.path === "/confirm/:confirmationCode") {
  //   const res = await verifyUserEmail(props?.match?.params?.confirmationCode);
  //   console.log('verify email response ', res)
  // }

  if (isLoading == 'wait') {
    return (
      <div className="container">
        <MyLoader show={true} />
      </div>
    )
  } else if (isLoading == 'fail') {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>
            <strong>Account not verified. please try again.</strong>
          </h3>
        </header>
        <Link to={"/login"}>
          Please Login
        </Link>
      </div>
    )
  } else if (isLoading == 'done') {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>
            <strong>Account confirmed!</strong>
          </h3>
        </header>
        <Link to={"/login"}>
          Please Login
        </Link>
      </div>
    )
  }
};

export default Welcome;
