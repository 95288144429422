import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const baseUrl = process.env.REACT_APP_BASE_URL

export const signupUser = createAsyncThunk(
  'users/register',
  async ({ name, email, password }, thunkAPI) => {
    try {
      const response = await fetch(
        baseUrl + 'api/auth/register',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            email,
            password,
          }),
        }
      );
      let data = await response.json();
      console.log('data', data);

      if (response.status === 200) {
        return { ...data, name: name, email: email };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const loginUser = createAsyncThunk(
  'users/login',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await fetch(
        baseUrl + 'api/auth/login',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        const token = data.data.token
        localStorage.setItem('token', token);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getCurrentUser = createAsyncThunk(
  'users/me',
  async ({ token }, thunkAPI) => {
    try {
      const response = await fetch(
        baseUrl + 'api/auth/me',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: "Bearer " + token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);




export const searchResults = createAsyncThunk(
  'api/home/searchResult',
  async ({ token, body }, thunkAPI) => {
    try {
      const response = await fetch(
        baseUrl + 'api/home/searchResult',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: "Bearer " + token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const purchaseRequest = createAsyncThunk(
  'api/home/purchaseRequest',
  async ({ token, body }, thunkAPI) => {
    try {
      const response = await fetch(
        baseUrl + 'api/home/purchaseRequest',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: "Bearer " + token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    name: '',
    email: '',
    user: null,
    searchResult: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [signupUser.fulfilled]: (state, { payload }) => {
      // console.log('payload', payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload?.user?.email;
      state.name = payload.user?.name;
    },
    [signupUser.pending]: (state) => {
      state.isFetching = true;
    },
    [signupUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      console.log('payload', payload)
      state.email = payload.email;
      state.name = payload.name;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },


    [getCurrentUser.pending]: (state) => {
      state.isFetching = true;
    },
    [getCurrentUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.user.email;
      state.name = payload.user.name;
      state.user = payload.user
      // console.log('current user ', payload.user)
    },
    [getCurrentUser.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },


    [searchResults.pending]: (state) => {
      state.isFetching = true;
    },
    [searchResults.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      // console.log('payload results-->', payload)
      state.searchResult = payload;
      state.user = payload.user
    },
    [searchResults.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },



    [purchaseRequest.pending]: (state) => {
      state.isFetching = true;
    },
    [purchaseRequest.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
    [purchaseRequest.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;
