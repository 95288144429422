import React from "react";
import { useState } from "react";
import refresh from "../images/refresh.png";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import toast from "react-hot-toast";

const TechSupportModal = ({ message, show, setShow, reportPress }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const reporProblem = () => {
    // const text = 'this is text'
    if (name == '') {
      toast.error('Name is require')
      return
    }
    if (email == '') {
      toast.error('Email is require')
      return
    }
    if (text == '') {
      toast.error('Text is required')
      return
    }
    reportPress({ text, name, email })
  }
  return (
    <div className="d-inline-flex">
      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{message ? message : 'Report A Problem'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="techSupport login-form">


          <div className="mt-1">
            <div className="position-relative">
              <input
                id="Name"
                name="Name"
                type="Name"
                onChange={e => setName(e.target.value)}
                autoComplete="Name"
                placeholder="Name "
                required
                className="appearance-none block w-100 border"
              />
            </div>
          </div>
          <div className="mt-1">
            <div className="position-relative">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                onChange={e => setEmail(e.target.value)}
                placeholder="Email Address"
                required
                className="appearance-none block w-100 border"
              />
            </div>
          </div>


          <textarea className="textareaReport" id="w3review" name="w3review" rows="4" cols="60"
            onChange={e => setText(e.target.value)}
            placeholder="Write here...">
          </textarea>

          {/* <p className="pb-2 pt-3">
            *It is recommended to open your search criteria*
          </p> */}
          <button onClick={reporProblem} className="w-75 m-auto d-block justify-center py-2 px-4 mt-4 mb-4 border upgrade-btn">
            Proceed
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TechSupportModal;
