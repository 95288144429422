import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const EditModal = ({ user, updateUser, deleteUser }) => {
  const [show, setShow] = useState(false);
  const [updates, setUpdates] = useState({
    name: '',
    searchCount: 0,
    password: ''
  })

  useEffect(() => {
    if (user) {
      setUpdates({ name: user.name, searchCount: user.searchCount })
    }
  }, [user])


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUpdate = () => {
    updateUser(user, updates);
    handleClose()
  }
  const handleDelete = (status) => {
    deleteUser(user._id,status)
    handleClose()
  }

  const onChangeText = (e) => {
    setUpdates({ ...updates, [e.target.name]: e.target.value })
  }
  return (
    <div>
      {" "}
      <Button className="edit-btn" variant="primary" onClick={handleShow}>
        Edit
      </Button>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label className="text-left d-block">Name</Form.Label>
            <Form.Control type="name" placeholder="Enter Updated Name"
              name="name"
              value={updates.name}
              onChange={onChangeText} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="text-left d-block">Search Count</Form.Label>
            <Form.Control type="searchCount" placeholder="Enter Updated Search Count" name="searchCount"
              value={updates.searchCount}
              onChange={onChangeText} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="text-left d-block">Password</Form.Label>
            <Form.Control type="purchase" placeholder="Enter Updated Password" name="password"
              value={updates.password}
              onChange={onChangeText} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="map-header footer-container">
          {!user.deleteStatus && <Button className="edit-btn bg-red" variant="primary" onClick={()=>handleDelete(true)}>
            Delete
          </Button>}
          {user.deleteStatus && <Button className="edit-btn bg-red" variant="secondary" onClick={()=>handleDelete(false)}>
            Activate
          </Button>}
          <div>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button onClick={handleUpdate}>Updated</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditModal;
