import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "./slices/UserSlice";
import MainHeader from "../components/MainHeader";
import { Button, Form } from "react-bootstrap";
import {
  fetchAllUsersAPI,
  fetchSearchHistoryAPI,
  fetchTransactionHistoryAPI,
  sendPurchaseRequestApi,
  updateUserAPI,
} from "../helpers/apiHelper";
import EditModal from "../components/EditModal";
import { MyLoader } from "../components/MyLoader";

const TransactionHistory = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [numberOfSearches, setNumberOfSearches] = useState(10);
  const [selected, setSelected] = useState("users");
  // const dispatch = useDispatch();
  useEffect(() => {
    // const token = localStorage.getItem("token");
    // dispatch(getCurrentUser({ token: token }));
    fetchSearchHistory();
  }, []);


  const fetchSearchHistory = async () => {
    try {
      setSelected("searchHistory");
      setIsLoading(true);
      const sHistory = await fetchTransactionHistoryAPI();
      setIsLoading(false);
      if (sHistory.status) {
        setSearchHistory(sHistory.list);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };






  return (
    <div>
      {/* <ErrorModal error={error} show={showError} setShow={setShowError} /> */}
      <MainHeader currPage={"transactionHistory"} />
      <div className="">
        <div className="map-header mt-3">
          <div className="row justify-content-center">
            <div className="col-md-2 side-bar">
              <button >Transaction History</button>
            </div>

            <div className="col-md-9 table-box">
              <div className="row justify-content-between m-4 ">
                <div className="col-md-4">Transaction History</div>
                <div className="col-md-4"></div>
              </div>
              <MyLoader show={isLoading} />
              {!isLoading && (
                <table>
                  <tbody>
                    <tr>
                      <th>Credit</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Reason</th>
                    </tr>
                    {searchHistory.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.no_of_searches}</td>
                          <td>{(parseInt(item?.price)/100).toFixed(2) }</td>
                          <td>{item?.status}</td>
                          <td>{item?.reason}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;

const MyLoader2 = ({ show }) => {
  return show ? (
    <div className="loader-container">
      <svg
        className="animate-spin h-6 w-6 center "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  ) : null;
};
