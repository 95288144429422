import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

export default function MyPagination({ length, pageSize, currentPage, setCurrentPage }) {

    const [totalPages, setTotalPages] = useState([])
    const [pageToShow, setPageToShow] = useState([])
    useEffect(() => {
        if (length && pageSize) {
            getVisiblePages(1)

        }
    }, [length, pageSize])

    const getVisiblePages = (page) => {
        let tPages = getPages(length, pageSize);
        setTotalPages(tPages)
        if (tPages.length < 5) {
            setPageToShow(tPages)
        } else {
            //   console.log('pages', page, tPages.length - 2)
            let pts = []
            if (page > tPages.length - 3) {
                pts.push(tPages[0])
                pts.push(0)
                pts.push(tPages[tPages.length - 3])
                pts.push(tPages[tPages.length - 2])
                pts.push(tPages[tPages.length - 1])
            }
            else if (page > 1 && page < tPages.length) {
                pts.push(page - 1)
                pts.push(page)
                pts.push(page + 1)
                pts.push(0)
                pts.push(tPages[tPages.length - 1])
            }
            else {
                pts.push(page)
                pts.push(page + 1)
                pts.push(page + 2)
                pts.push(0)
                pts.push(tPages[tPages.length - 1])
            }
            setPageToShow(pts)
        }
    }

    const setCurrentPageLocal = (page) => {
        setCurrentPage(page)
        getVisiblePages(page)
    }

    const getPages = (l, pSize) => {
        let pp = parseInt(l / pSize)
        pp = l % pSize == 0 ? pp : (pp + 1)
        return [...Array(pp)].map((_, i) => i + 1)
    }

    return !length ? null: (
        <Pagination size="lg">
            <Pagination.Prev onClick={() => currentPage > 1 ? setCurrentPageLocal(currentPage - 1) : null} />
            {pageToShow.map((i, index) => (
                currentPage === i ?
                    <Pagination.Item active={currentPage === i} key={index}>{i}</Pagination.Item> :
                    i === 0 ?
                        <Pagination.Ellipsis key={index} />
                        :
                        <Pagination.Item onClick={() => setCurrentPageLocal(i)} active={currentPage === i} key={index}>{i}</Pagination.Item>

            ))
            }
            <Pagination.Next onClick={() => currentPage < totalPages[totalPages.length - 1] ? setCurrentPageLocal(currentPage + 1) : null} />
        </Pagination>
    );
}
