


const baseUrl = process.env.REACT_APP_BASE_URL

const getToken = () => {
    return localStorage.getItem("token");
}

export const verifyUserEmail = async (code) => {
    try {
        const response = await fetch(
            baseUrl + "api/auth/confirm/" + code,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            }
        );
        let data = await response.json();

        if (response.status === 200) {
            return { ...data };
        } else {
            return { ...data };
        }
    } catch (e) {
        console.log('Error', e.message);
        return (e?.message);
    }
}
export const sendPurchaseRequestApi = async ({body }) => {
    const token = getToken()
    try {
        const response = await fetch(
            baseUrl + 'api/home/purchaseRequest',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }
        );
        let data = await response.json();

        if (response.status === 200) {
            return { ...data };
        } else {
            return { ...data };
        }
    } catch (e) {
        console.log('Error', e.message);
        return (e?.message);
    }
}

export const fetchAllUsersAPI = async () => {
    const token = getToken()
    try {
        const response = await fetch(
            baseUrl + 'api/auth/allUsers',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json',
                },
                // body: JSON.stringify(body)
            }
        );
        let data = await response.json();

        if (response.status === 200) {
            return { status: true, list: data.data };
        } else {
            return { status: false, list: [], error: JSON.stringify(data) };
        }
    } catch (e) {
        console.log('Error', e.message);
        return { status: false, list: [], error: JSON.stringify(e.message) };
    }
}

export const fetchSearchHistoryAPI = async () => {
    const token = getToken()
    try {
        const response = await fetch(
            baseUrl + 'api/home/getSearchHistory',
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json',
                },
                // body: JSON.stringify(body)
            }
        );
        let data = await response.json();

        if (response.status === 200) {
            return { status: true, list: data.data };
        } else {
            return { status: false, list: [], error: JSON.stringify(data) };
        }
    } catch (e) {
        console.log('Error', e.message);
        return { status: false, list: [], error: JSON.stringify(e.message) };
    }
}
export const fetchTransactionHistoryAPI = async () => {
    const token = getToken()
    try {
        const response = await fetch(
            baseUrl + 'api/home/getTransactionHistory',
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json',
                },
                // body: JSON.stringify(body)
            }
        );
        let data = await response.json();

        if (response.status === 200) {
            return { status: true, list: data.data };
        } else {
            return { status: false, list: [], error: JSON.stringify(data) };
        }
    } catch (e) {
        console.log('Error', e.message);
        return { status: false, list: [], error: JSON.stringify(e.message) };
    }
}

export const updateUserAPI = async ({ body }) => {
    const token = getToken()
    try {
        const response = await fetch(
            baseUrl + 'api/auth/updateUser',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }
        );
        let data = await response.json();

        if (response.status === 200) {
            return { status: true, list: data.data };
        } else {
            return { status: false, list: [], error: JSON.stringify(data) };
        }
    } catch (e) {
        console.log('Error', e.message);
        return { status: false, list: [], error: JSON.stringify(e.message) };
    }
}


export const resetPasswordAPI = async ({ body }) => {
    try {
        const response = await fetch(
            baseUrl + 'api/auth/resetPassword',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }
        );
        let data = await response.json();

        if (response.status === 200) {
            return { status: true, data: data };
        } else {
            return { status: false, error: JSON.stringify(data) };
        }
    } catch (e) {
        console.log('Error', e.message);
        return { status: false, list: [], error: JSON.stringify(e.message) };
    }
}
export const resetPasswordUpdateAPI = async ({ body }) => {
    try {
        const response = await fetch(
            baseUrl + 'api/auth/resetPasswordUpdate',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }
        );
        let data = await response.json();

        if (response.status === 200) {
            return { status: true, data: data };
        } else {
            return { status: false, error: JSON.stringify(data) };
        }
    } catch (e) {
        console.log('Error', e.message);
        return { status: false, list: [], error: JSON.stringify(e.message) };
    }
}
export const updateSettingsApi = async ({ body }) => {
    const token = getToken()
    try {
        const response = await fetch(
            baseUrl + 'api/auth/updateSettings',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }
        );
        let data = await response.json();

        if (response.status === 200) {
            return { status: true, list: data.data };
        } else {
            return { status: false, list: [], error: JSON.stringify(data) };
        }
    } catch (e) {
        console.log('Error', e.message);
        return { status: false, list: [], error: JSON.stringify(e.message) };
    }
}



export const searchResultsAPI = async (body) => {
    const token = getToken()
    try {
        const response = await fetch(
            baseUrl + 'api/home/searchResult',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }
        );
        let data = await response.json();

        if (response.status === 200) {
            return { status: true, data: data };
        } else {
            return { status: false,  };
        }
    } catch (e) {
        console.log('Error', e.message);
        return { status: false, list: [], error: JSON.stringify(e.message) };
    }
}

export const reportAProblemAPI = async (body) => {
    const token = getToken()
    try {
        const response = await fetch(
            baseUrl + 'api/home/reportProblem',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return { status: true, data: data };
        } else {
            return { status: false,  };
        }
    } catch (e) {
        console.log('Error', e.message);
        return { status: false, list: [], error: JSON.stringify(e.message) };
    }
}
export const techSupportAPI = async (body) => {
    const token = getToken()
    try {
        const response = await fetch(
            baseUrl + 'api/home/techSupport',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return { status: true, data: data };
        } else {
            return { status: false,  };
        }
    } catch (e) {
        console.log('Error', e.message);
        return { status: false, list: [], error: JSON.stringify(e.message) };
    }
}